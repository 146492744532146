import * as React from "react"
import { Link, graphql } from "gatsby"
import FAQCard from "../../components/FAQCard"
import SymptomsCard from "../../components/SymptomsCard"
import AdmissionGreetingCard from "../../components/AdmissionGreetingCard"

import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";


const CaseStudiesPage = ({ data, pageContext }) => (
  <Layout context={pageContext} openGraph={{
    url: pageContext.localizedUrls[pageContext.locale],
    title: pageContext.title,
    description: pageContext.description,
    images: [
      {
        url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
      }
    ]
  }}>
    <PageHeader
      title={data.firestore.page.content[pageContext.locale]['headerTitle']}
      imageUrl={data.firestore.page.content.headerImage}
    />
    <main className="my-12 flex flex-col lg:flex-row justify-evenly w-full px-8">
      <section className="text-coconut mb-16 lg:mb-0">
        {/* <h2 className="text-center text-coconut font-semibold text-xl lg:text-2xl mb-4">{pageContext.locale==='en'?"Cases We've Treated":"個案實例"}</h2> */}
        <div className="space-y-12 prose-sm lg:prose">
          {
            data.firestore.caseStudies.map(item => (
              <div className="flex items-center max-w-3xl" key={item.id}>
                <img className="rounded-full h-32 w-32 lg:h-40 lg:w-40" src={item.image} alt={item.title}/>
                <div className="flex flex-col ml-4 lg:ml-8">
                  <h3 className="font-semibold text-mango text-base my-2 lg:text-2xl lg:my-3">
                    <Link to={item.href}>
                      { item.title }
                    </Link>
                  </h3>
                  <p >{item.description}</p>
                </div>
              </div>
            ))
          }
        </div>
      </section>
      <aside className="items-center flex flex-col space-y-6 lg:items-start">
        <AdmissionGreetingCard/>
        <SymptomsCard/>
        <FAQCard/>
      </aside>
    </main>
  </Layout>
)

export default CaseStudiesPage

export const query = graphql`
  query CaseStudiesPageQuery($pageId: ID, $locale: String!) {
    firestore {
      page(id: $pageId) {
        content
      }
      caseStudies(locale: $locale) {
        id
        title
        description
        href
        image
      }
    }
  }
`